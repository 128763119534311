<template>
  <div>
    <div class="checkbox"
         :style="{'background':flag?'#4b9efb':''}"
         @click="$emit('changeflag',!flag)">
      <div class="gou"
           :style="{'transform':flag?'rotate(-45deg)':'','opacity':flag?'1':'0'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['flag'],
  model: {
    prop: 'flag',
    event: 'changeflag'
  }
}
</script>

<style lang="stylus" scoped>
.checkbox
  width 15px
  height 15px
  border 1px solid #cccccc
  border-radius 2px
  position relative
  .gou
    width 10px
    height 6px
    border 1px solid #ffffff
    border-top none
    border-right none
    position absolute
    left calc(50% - 5px)
    top calc(50% - 5px)
    transition all 0.3s
</style>
