<template>
  <div class="content">
    <div class="filtercase"
         ref="ctn"
         :style="{'right':showflag?'0px':(this.wdt*-1+'px')}">
      <div class="title">
        <div class="cancel"
             @click="cancel">取消</div>
        <div class="title_text">筛选条件</div>
        <div class="right"
             @click="submit">确定</div>
      </div>
      <div class="filter">
        <ul class="tags">
          <li v-for="(item,index) in data"
              :style="{'background-color':(index === twiceName ? 'white' : '')}"
              :key="index"
              @click="changeDir(index)">
            {{index}}
          </li>
        </ul>
        <ul class="_content">
          <li v-for="(item,index) in data[twiceName]"
              :key="index">
            <div @click="cancelOther(item.flag,index)">
              <checkBox v-model="item.flag"></checkBox>
            </div>
            <span>{{item.name+ '\t\t\t\t' +item.num}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="black_tc"
         v-show="this.showflag"
         @click="cancel"></div>
  </div>
</template>

<script>
import checkBox from '@/pages/Search/compoments/checkBox'
// import { caseBase } from '~api-config'
export default {
  props: ['showflag', 'conditions'],
  components: {
    checkBox
  },
  model: {
    prop: 'showflag',
    event: 'changeshow'
  },
  data () {
    return {
      // 左侧高亮元素的index
      mainActiveIndex: 0,
      // 被选中元素的id
      activeId: 1,
      activeNames: [],
      tip: false,
      wdt: 0,
      data: {},
      twiceName: {}
    }
  },
  methods: {
    cancelOther (flag, index) {
      if (flag) {
        this.data[this.twiceName].forEach((item, num) => {
          if (index === num) { return false }
          this.$set(this.data[this.twiceName][num], 'flag', false)
        })
      }
    },
    cancel () {
      // this.showflag = false
      this.$emit('changeshow', false)
    },
    submit () {
      for (const item in this.data) {
        for (let i = 0; i < this.data[item].length; i++) {
          if (this.data[item][i].flag) {
            this.$emit('changeshow', false)
            this.$emit('submit', this.data[item][i].key.trim())
          }
        }
      }
    },
    changeDir (itemName) {
      for (const item in this.data) {
        this.data[item].forEach((_item, index) => {
          this.$set(this.data[item][index], 'flag', false)
        })
      }
      this.twiceName = itemName
    }
  },
  mounted () {
    this.wdt = this.$refs.ctn.offsetWidth
    let canshowflag = false; let startX = 0; let endX = 0
    this.startMove = (e) => {
      if (e.changedTouches[0].pageX < window.screen.width - 30) { canshowflag = false } else { canshowflag = true; startX = e.changedTouches[0].pageX }
    }
    this.endMove = (e) => {
      if (!canshowflag) {
        return false
      }
      endX = e.changedTouches[0].pageX
      if (startX - endX > 50) {
        this.$emit('changeshow', true)
      }
    }
    window.addEventListener('touchstart', this.startMove, false)
    window.addEventListener('touchend', this.endMove, false)
  },
  created () {
    // this.$axios.post(`${pydataBase}/api/party/card?name=桂林源德汽车运输服务有限责任公司`).then(res => {
    //   this.res = res.data.result.conditions
    // })
  },
  destroyed () {
    window.removeEventListener('touchend', this.endMove)
    window.removeEventListener('touchstart', this.startMove)
  },
  watch: {
    conditions: {
      deep: true,
      handler (val) {
        this.data = {}
        this.twiceData = {}
        val.forEach((item, index) => {
          if (index === 0) { this.twiceName = item.title }
          this.$set(this.data, item.title, new Array(item.data.length))
          item.data.forEach((_item, num) => {
            const obj = {}
            for (const name in _item) {
              obj[name] = _item[name]
            }
            this.$set(this.data[item.title], num, obj)
            this.$set(this.data[item.title][num], 'flag', false)
          })
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
*
  transition all 0.5s
.content
  width 100%
  overflow-x hidden
  .filtercase
    width 70%
    height 100%
    overflow auto
    background #ffffff
    position fixed
    z-index 100001
    right 0px
    top 0px
    transition all 0.5s
    .filter
      width 100%
      height calc(100% - 54px)
      .tags
        width 30%
        height 100%
        background-color #f6f6f6
        overflow auto
        float left
        font-size 13px
        fony-weight 400
        li
          width 100%
          min-height 40px
          overflow hidden
          text-align center
          line-height 40px
          color #333333
      ._content
        width 70%
        height 100%
        float right
        li
          width 100%
          min-height 40px
          padding 10px
          overflow hidden
          text-align left
          color #333333
          font-size 13px
          display flex
          div
            align-self center
          span
            margin-left 10px
            align-self center
    .title
      width 100%
      height 54px
      line-height 54px
      display flex
      padding 5px
      padding-left 10px
      padding-right 10px
      box-sizing border-box
      justify-content space-between
      div:nth-child(1)
        color #CCCCCC
        font-size 15px
      div:nth-child(2)
        color #000000
        font-size 15px
      div:nth-child(3)
        color #4B9EFB
        font-size 15px
    .anas
      li
        width 100%
        height 30px
        line-height 30px
        color #000000
        display flex
        justify-content space-between
        font-size 14px
  .black_tc
    width 100%
    height 100%
    background black
    opacity 0.5
    position fixed
    top 0px
    left 0px
    z-index 10000
</style>
