var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "checkbox",
        style: { background: _vm.flag ? "#4b9efb" : "" },
        on: {
          click: function ($event) {
            return _vm.$emit("changeflag", !_vm.flag)
          },
        },
      },
      [
        _c("div", {
          staticClass: "gou",
          style: {
            transform: _vm.flag ? "rotate(-45deg)" : "",
            opacity: _vm.flag ? "1" : "0",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }